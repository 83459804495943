import { lazy, Suspense, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AppState from "./context/AppState";
import { NotificationsProvider } from "@mantine/notifications";
import {
  MantineProvider,
  ColorSchemeProvider,
  ColorScheme,
  AppShell,
} from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import DrivenLoader from "./components/DrivenLoader";

const Admin = lazy(() => import("./pages/Admin"));
const ActivateAccount = lazy(() => import("./pages/ActivateAccount"));
const Landing = lazy(() => import("./pages/Landing"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const PageNotFound = lazy(() => import("./pages/PageNotFound"));

function App() {
  const [appLoading, setAppLoading] = useState<boolean>(false);
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: "vips-theme",
    defaultValue: "light",
    getInitialValueInEffect: true,
  });
  const toggleColorScheme = () => {
    setAppLoading(true);
    setTimeout(() => {
      setColorScheme((current: "dark" | "light") =>
        current === "dark" ? "light" : "dark"
      );
      setAppLoading(false);
    }, 0);
  };

  return (
    <AppState>
      {appLoading ? (
        <DrivenLoader />
      ) : (
        <MantineProvider
          theme={{ colorScheme }}
          withGlobalStyles
          withNormalizeCSS
        >
          <ColorSchemeProvider
            colorScheme={colorScheme}
            toggleColorScheme={toggleColorScheme}
          >
            <NotificationsProvider position={"top-center"}>
              <AppShell navbarOffsetBreakpoint="sm" asideOffsetBreakpoint="sm">
                <Router>
                  <Suspense fallback={<DrivenLoader />}>
                    <Routes>
                      <Route path="/" element={<Landing />} />
                      <Route
                        path="/admin"
                        element={<Admin appLoading={appLoading} />}
                      />
                      <Route
                        path="/activateAccount"
                        element={<ActivateAccount />}
                      />
                      <Route
                        path="/resetPassword"
                        element={<ResetPassword />}
                      />
                      <Route path="*" element={<PageNotFound />} />
                    </Routes>
                  </Suspense>
                </Router>
              </AppShell>
            </NotificationsProvider>
          </ColorSchemeProvider>
        </MantineProvider>
      )}
    </AppState>
  );
}

export default App;
