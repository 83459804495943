import { Box, Text } from "@mantine/core";
import { CirclesWithBar } from "react-loader-spinner";
import { memo } from "react";

export default memo(function DrivenLoader() {
  return (
    <Box
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%,-50%)",
        textAlign: "center",
      }}
    >
      <CirclesWithBar
        color="rgb(51, 154, 240)"
        height="150"
        width="150"
        ariaLabel="Loading. Please wait."
        visible={true}
      />
      <Text weight={700} variant="gradient" mt={12} size="sm">
        &copy; 2023 Driven Brands
      </Text>
      <Text color="dimmed" size="xs">
        All Rights Reserved
      </Text>
    </Box>
  );
});
