import { AppContext } from "./AppContext";
import { useLocalStorage } from "@mantine/hooks";

export default function AppState(props) {
  const [storage, setStorage] = useLocalStorage({
    key: "vips",
    defaultValue: {
      user: "",
    },
  });

  return (
    <AppContext.Provider value={{ storage, setStorage }}>
      {props.children}
    </AppContext.Provider>
  );
}
